import _ from 'lodash'
import React from 'react'
import moment from 'moment'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { device } from '../../../../theme/device'
import {
  ButtonMore, H2, P, Wrapper, NoImage,
} from '../../../../components'
import '../../newsroom-style.scss'

const WrapperNewsCard = styled.div`
  display: flex;
  flex: 1 1 calc(100% - 40px);
  align-items: flex-start;
  margin-bottom: 88px;
  box-sizing: border-box;
  @media ${device.tablet} {
    padding: 24px;
    flex: 1 1 300px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 300px;
  }
  @media ${device.desktop} {
    flex: 1 1 50%;
    margin-bottom: 0px;
    justify-content: center;

  }
  @media ${device.large} {
    padding: 0;
    justify-content: flex-start;
  }
`

const Tag = styled.span`
  background-color: #162934;
  text-transform: uppercase;
  border-radius: 2px;
  font-family: Manrope;
  font-weight: 500;
  color: #fff;
  padding: 5px 5px;
  font-size: 10px;
`

const WrapperPicture = styled.div`
  width: 100%;
  height: 220px;
  position: relative;
  overflow: hidden;
  @media ${device.desktop} {
    padding-bottom: 100%;
    height: 0;

    max-width: 530px;
    margin: 0
  }
  img {
    transition: 0.4s ease;
    position: absolute;
  }
  &:hover img {
    transform: scale(1.08);
  }
`

const Card = ({ news, t }) => (
  <WrapperNewsCard>
    <Wrapper
      flexDirectionD="column"
      widthD="530px"
      marginD="0
    ">
      <Link
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
        to={`/newsroom/${_.get(news, 'slug')}`}>
        <WrapperPicture
        >
          {
            _.get(news, 'featured_image.url')
              ? (
                <img
                  className="image-newsroom"
                  src={_.get(news, 'featured_image.url')}
                />
              ) : (
                <NoImage />
              )
          }
        </WrapperPicture>
      </Link>

      <Wrapper
        flexDirectionD="column"
        justifyContentD="flex-start"
        alignItems="flex-start"
      >
        <Wrapper
          flexDirectionM="row"
          marginD="36px 0 9px"
          marginM="26px 0 10px"
          justifyContentM="flex-start"
          alignItemsM="flex-start"
          justifyContentD="flex-start">
          <P
            style={{
              fontSize: '13px',
              marginRight: '10px',
              textTransform: 'uppercase',
            }}>
            {moment(_.get(news, 'date')).format('MMMM DD, YYYY')}
          </P>
          <Tag>{_.get(news, 'category_name')}</Tag>
        </Wrapper>
        <Wrapper
          marginM="0 0 54px"
          justifyContentM="flex-start"
          alignItemsM="flex-start"
          justifyContentD="flex-start">
          <H2 lineHeightD="6xl">{_.get(news, 'title.rendered')}</H2>
        </Wrapper>
        <Wrapper
          heightD="76px"
          paddingD="0"
          marginD="80px 0 40px"
          paddingM="0"
          alignItemsM="flex-start"
          justifyContentM="flex-start"
          justifyContentD="flex-start">
          <ButtonMore
            right
            link={`/newsroom/${_.get(news, 'slug')}`}
            toright >{t('newsroom.read')}</ButtonMore>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  </WrapperNewsCard>
)

export default Card
