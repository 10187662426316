import _ from 'lodash'
import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { device } from '../../theme/device'
import './newsroom-style.scss'
import {
  Spinner, Wrapper, Subtitle, H1, Button, Seo,
} from '../../components'
import { Card } from './components'

const WrapperNews = styled.div`
   display: block;
   width: calc(100% - 0px);
   padding: 56px 25px;
   box-sizing: border-box;
   gap: 12px;
   @media ${device.table} {
     display: flex;
     align-items: baseline;
    flex-wrap: wrap;
    gap: 0;
  }
   @media ${device.desktop} {
     display: flex;
     align-items: flex-start;
     flex-wrap: wrap;
     margin-top: 120px;
     flex-direction: row;
     width: 100%;
     max-width: 1240px;
     padding: 0;
     > div:nth-child(even) {
       justify-content: center;
       margin-top: 150px;
      }
   }
   @media ${device.large} {
    > div:nth-child(even) {
      justify-content: flex-end;
     }
   }

`

const NewsroomView = ({
  loading,
  years,
  onHandleSelectYear,
  filteredNews,
  hasMore,
  totalNews,
  onLoadMore,
  loadingMore,
  t,
}) => {
  if (loading) {
    return <Spinner loading={loading} />
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: 50,
      borderRadius: '2px',
      borderColor: '#7B7B7B',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: '#7B7B7B',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#7B7B7B', // Custom colour
    }),
    menuList: (provided) => ({
      ...provided,
      fontFamily: "'gorditaregular', sans-serif",
      fontSize: '14px',
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      paddingLeft: '15px',
    }),

  }

  return (
    <React.Fragment>
      <Seo title={t('newsroom.title')}/>
      <Wrapper
        justifyContentD="flex-start"
        style={{
          minHeight: 'calc(200vh - 100px)',
        }}
        paddingD="90px 0"
        flexDirectionD="column"
      >
        <Wrapper
          widthD="1920px"
          flexDirectionD="column"
        >
          <Wrapper
            widthD="750px"
            flexDirectionD="column"
            marginD="0 0 10px"
            paddingM="0 25px"
            marginM="35px 0"
            style={{ overflow: 'hidden' }}
          >
            <Subtitle style={{ marginBottom: '12px' }}>{t('newsroom.title')}</Subtitle>
            <Wrapper style={{ textAlign: 'center' }} widthD="700px" marginD="0 0 32px 0">
              <H1>{t('newsroom.subtitle')}</H1>
            </Wrapper>
          </Wrapper>
          <Wrapper
          >
            <Select
              menuPortalTarget={typeof document !== 'undefined' ? document.body : null }
              menuPosition={'fixed'}
              placeholder={t('newsroom.filter')}
              options={years}
              className="select-years"
              styles={customStyles}
              isSearchable={false}
              isClearable={true}
              onChange={(e) => onHandleSelectYear(e)}
              components={{ IndicatorSeparator: () => null }}
            />
          </Wrapper>
          <WrapperNews
          >
            {_.map(filteredNews, (news) => (
              <Card to={news.id} news={news} t={t} key={news.id}/>
            ))}

          </WrapperNews>
          {
            loadingMore && <Spinner height="100px" loading={loadingMore} />
          }
          {
            hasMore && !loadingMore
            && <Button
              onClick={onLoadMore}
              widthM="200px"
              widthD="200px">
              {t('newsroom.more')}({totalNews - filteredNews.length})
            </Button>
          }
          <Wrapper marginM="0 0 60px 0">

          </Wrapper>

        </Wrapper>
      </Wrapper >
    </React.Fragment>
  )
}

export default NewsroomView
