import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import _ from 'lodash'
import NewsroomView from './newsroom-view'
import { useNewsroom } from '../../hooks/use-news'

const NewsRoomController = () => {
  const [allNews, setAllNews] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [totalNewsFiltered, setTotalNewsFiltered] = useState(0)
  const [loadingMore, setLoadingMore] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedYear, setSelectedYear] = useState(undefined)
  const [filteredNews, setFilteredNews] = useState([])

  const { t, i18n } = useTranslation()
  const {
    news,
    loading,
    years,
    totalNews,
    getNews,

  } = useNewsroom()

  const onChangeSelectYear = (e) => {
    setSelectedYear(e)
  }
  const onLoadMore = () => {
    setPage(page + 1)
  }

  useEffect(() => {
    if (!_.isEmpty(selectedYear)) {
      setLoadingMore(true)
      if (page === 1) {
        getNews(page, selectedYear.value)
          .then((resp) => {
            setFilteredNews(resp.data)
            setLoadingMore(false)
            setHasMore(resp.data.length > 10)
            setTotalNewsFiltered(resp.total_post)
          })
      } else {
        getNews(page, selectedYear.value)
          .then((resp) => {
            setFilteredNews([...allNews, ...resp.data])
            setLoadingMore(false)
            setHasMore([...resp.data, ...allNews].length < resp.total_post)
            setTotalNewsFiltered(resp.total_post)
          })
      }
    } else {
      setFilteredNews(news)
      setHasMore(totalNews > 10)
      setSelectedYear(undefined)
    }
  }, [page, selectedYear])

  useEffect(() => {
    if (page > 1 && selectedYear === undefined) {
      setLoadingMore(true)
      getNews(page)
        .then((resp) => {
          setFilteredNews([...allNews, ...resp.data])
          setLoadingMore(false)
          setHasMore([...resp.data, ...allNews].length > totalNews)
        })
    }
  }, [page])

  useEffect(() => {
    if (page === 1) {
      setAllNews(news)
      setFilteredNews(news)
      setTotalNewsFiltered(totalNews)
      setHasMore(totalNews > 10)
    }
  }, [news])

  const viewProps = {
    loading,
    filteredNews,
    years,
    totalNews: totalNewsFiltered,
    hasMore,
    onHandleSelectYear: onChangeSelectYear,
    onLoadMore,
    loadingMore,
    t,
  }

  return (

    <NewsroomView {...viewProps}/>
  )
}

export default NewsRoomController
